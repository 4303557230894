.dropdown-menu {
  box-shadow: $box-shadow;
  user-select: none;
  margin: 0 !important;

  &.card {
    padding: 0;
    min-width: $dropdown-max-width;
    display: none;

    &.show {
      display: flex;
    }
  }
}

.dropdown-menu-dark {
    color: #fff !important;
    background-color: #202020 !important;
    border-color: #202020 !important;
}

.dropdown-item {
  min-width: $dropdown-min-width;
  display: flex;
  align-items: center;
  margin: 0;
  line-height: $line-height-base;
}

.dropdown-item-icon {
  width: $icon-size !important;
  height: $icon-size !important;
  margin-right: .5rem;
  color: $text-muted;
  opacity: $text-muted-opacity;
  text-align: center;
}

.dropdown-item-indicator {
  margin-right: .5rem;
  margin-left: -.25rem;
  height: 1.25rem;
  display: inline-flex;
  line-height: 1;
  vertical-align: bottom;
  align-items: center;
}

.dropdown-header {
  @include subheader;
  padding-bottom: .25rem;
  pointer-events: none;
}

.dropdown-menu-scrollable {
  height: auto;
  max-height: $dropdown-scrollable-height;
  overflow-x: hidden;
}

.dropdown-menu-column {
  min-width: $dropdown-min-width;

  .dropdown-item {
    min-width: 0;
  }
}

.dropdown-menu-columns {
  display: flex;
}

.dropdown-menu-arrow {
  //margin-top: .25rem;

  &:before {
    content: "";
    position: absolute;
    top: -.25rem;
    left: .75rem;
    display: block;
    background: inherit;
    width: 14px;
    height: 14px;
    transform: rotate(45deg);
    transform-origin: center;
    border: 1px solid;
    border-color: inherit;
    z-index: -1;
    clip: rect(0px, 9px, 9px, 0px);
  }

  &.dropdown-menu-end {
    &:before {
      right: .75rem;
      left: auto;
    }
  }
}

.dropend {
  > .dropdown-menu {
    margin-top: subtract(-$dropdown-padding-y, 1px);
    margin-left: -.25rem;
  }

  .dropdown-toggle {
    &:after {
      margin-left: auto;
    }
  }
}

.dropdown-menu-card {
  padding: 0;

  > .card {
    margin: 0;
    border: 0;
    box-shadow: none;
  }
}