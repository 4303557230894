.mainbar {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: calc(100vh - 60px);
  margin: 0;
  position: relative;
  padding-bottom: 0 !important;

  &.bordered {
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    padding: 0 !important;
  }

  &.detail-fixed {
    height: calc(100vh - 60px) !important;

    .page-header {
      max-height: 8vh !important;
      height: 80px !important;
    }

    .page-navigation {
      // max-height: 5.5vh !important;
      // min-height: 5.5vh !important;
      height: 55px !important;
    }

    .page-body {
      max-height: calc(100vh - 4vh - 60px - 5.5vh - 4vh) !important;
      overflow-y: scroll !important;
      overflow-x: hidden !important;
      scroll-behavior: smooth !important;
      scroll-padding-top: 4rem !important;
    }

    .page-footer {
      height: 8vh !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &.fixed {
    height: calc(100vh - 60px) !important;

    .page-header {
      max-height: 8vh !important;
      height: 80px !important;
    }

    .page-navigation {
      // max-height: 5.5vh !important;
      // min-height: 5.5vh !important;
      height: 55px !important;
    }

    .page-body {
      max-height: calc(100vh) !important;
      overflow-y: hidden !important;
      overflow-x: hidden !important;
    }

    .page-footer {
      height: 6vh !important;
      padding-left: 24px !important;
      padding-right: 24px !important;
      padding-top: 15px !important;
      padding-bottom: 15px !important;
    }

    .table {
      height: calc(100vh - 19.5vh) !important;

      thead {
        max-height: 5vh !important;
        display: block;
      }

      tbody {
        display: block;
        max-height: calc(100vh - 32vh) !important;
        overflow-y: auto;
        overflow-x: hidden;
        min-height: 400px !important;
      }

      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
    }
  }

  .page-header {
    padding: $content-padding-y  !important;
    margin: 0;
    max-height: 8vh !important;
    height: 80px !important;

    .nav-tabs {
      margin-top: 10px;
      border-bottom: none !important;

      .nav-link {
        &.active {
          background: $white;
        }
      }
    }
  }

  .page-navigation {
    background-color: $white;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    margin: 0;
    padding-left: $content-padding-y  !important;
    padding-right: $content-padding-y  !important;
    width: 100%;
    // height: 5.5vh !important;
    // height: auto !important;
    height: 55px !important;

    .nav {
      padding: 0;
      min-height: 52px;

      .nav-item {
        border-left: 1px solid $border-color;
      }

      .nav-link {
        height: 100%;
        color: $text-muted;

        svg {
          margin-right: 10px !important;
        }

        &.active {
          color: inherit !important;
          font-weight: 500;
          background-color: rgba($color: #F4F6FA, $alpha: 1.0);
        }
      }
    }
  }

  .page-body {
    background-color: $white;
    margin: 0;
    flex-grow: 1;

    &.padding {
      padding: $content-padding-y  !important;
    }

    &.bordered {
      border-top: 1px solid $border-color;
    }
  }

  .page-footer {
    padding: $content-padding-y ;
    border-top: 1px solid $border-color;
    width: 100%;
    z-index: 99;

    &.padding {
      padding: $content-padding-y  !important;
    }
  }

  .table-tools {
    padding: 10px 0 !important;

    .search {
      min-width: 200px;
      max-width: 200px;
      height: 35px;

      .input-group-text {
        cursor: pointer;
      }

      ::placeholder {
        color: hsl(0, 0%, 50%);
        font-size: 13px !important;
      }
    }

    .filter {
      display: flex;
      width: 100%;

      .input-group {
        max-width: 180px !important;
        min-width: 180px !important;

        .form-control {
          padding: 0.33rem 0.75rem !important;
          background-color: $white  !important;
          height: 35px !important;

          &::placeholder {
            color: hsl(0, 0%, 50%) !important;
            font-size: 13px !important;
          }
        }

        .input-group-text {
          background-color: $white  !important;
        }
      }

      .select__control {
        min-width: 180px;
        width: 100%;
        background-color: $white  !important;
        height: 35px;
      }

      >span {
        margin-left: 10px;
      }

      >div {
        margin-left: 10px;
      }
    }
  }

  table {

    th:first-child,
    td:first-child {
      padding-left: $content-padding-y  !important;
    }

    th:last-child,
    td:last-child {
      padding-right: $content-padding-y  !important;
    }
  }

}

@media only screen and (max-width: 990px) {
  .mainbar {
    display: block !important;
    align-items: unset !important;

    .page-header {
      height: auto !important;
    }

    &.detail-fixed {
      height: calc(100vh - 60px) !important;

      .page-header {
        height: 8vh !important;
      }

      .page-navigation {
        min-height: 5.5vh !important;
      }

      .page-body {
        max-height: calc(100vh - 8vh - 60px - 5.5vh - 16vh) !important;
      }

      .page-footer {
        height: 15vh !important;
      }
    }

    &.fixed {
      height: 100% !important;

      .page-header {
        height: auto !important;
      }

      .page-body {
        overflow-y: auto !important;
        overflow-x: auto !important;
      }

      .page-navigation {
        height: auto !important;

        .nav {
          .nav-item {
            border-left: none !important;
            border-bottom: 1px solid $border-color;
            padding: 2px 0px;
          }
        }
      }

      .page-body {
        height: auto !important;
      }

      .page-footer {
        height: auto !important;
      }

      .table {
        height: 100% !important;

        tbody {
          max-height: 100% !important;
          min-width: 1500px !important;
        }

        thead,
        tbody tr {
          display: table;
          width: 100%;
          table-layout: fixed !important;

          td {
            >div {
              white-space: initial !important;
            }
          }
        }
      }
    }

    .table-tools {
      .search {
        min-width: auto !important;
        max-width: 100% !important;
      }
    }

    .filter-mobile {
      .input-group {
        max-width: 100% !important;

        .form-control {
          padding: 0.33rem 0.75rem !important;
          background-color: $white  !important;

          &::placeholder {
            color: hsl(0, 0%, 50%) !important;
            font-size: 13px !important;
          }
        }

        .input-group-text {
          background-color: $white  !important;
        }
      }
    }
  }
}

.content-padding {
  padding: 10px $content-padding-y;
}